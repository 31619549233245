@import '@/assets/css/components/themeBox.scss';

.wrapper {
  .query {
    margin-bottom: 40px;
    margin-top: 70px;

    .calendar_content {
      @include screen-mobile1() {
        flex-direction: column;
      }

      margin-bottom: 30px;

      .calendar {
        grid-area: calendar;
      }

      .ib-button {
        margin: 0 10px;

        @include screen-mobile1() {
          height: 45px;
          margin: 10px auto;
        }
      }
    }

    .theme_box {
      flex-wrap: wrap;

      li {
        width: 31%;

        @include screen-mobile1() {
          width: 100%;
        } 
      }

      .box {
        display: flex;
        background: $black2;
        box-shadow: none;
        width: 31%;
        height: 104px;
        margin-top: 10px;
        border-radius: 5px;

        @include screen-mobile() {
          width: 100%;
        }

        & .active {
          background-color: none;
        }

        .icon-container {
          width: 104px;
          height: 104px;
          background-color: $theme-hue;
          border-radius: 5px;

          img {
            width: 60px;
          }
        }

        .box-content {
          padding-left: 20px;

          .top {
            font-size: 12px;
            color: $white;
          }
        }

        .bottom {
          margin: 0;
        }

        // .icon {
        //   height: 25px;
        //   text-align: right;

        //   .more {
        //     font-size: 25px;
        //     cursor: pointer;
        //     display: none;
        //   }
        // }

        // &.active .more {
        //   display: inline;
        // }
      }
    }
  }

  .tables {
    margin-top: 40px;

    .item_header {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }

    .table {
      // box-shadow: inset 0px 0px 5px 2px $tropical-green;
      border: none;
      background-color: $theme-hue;
      border-radius: 10px;

      /deep/ .el-table tr {
        background-color: transparent;

        th.el-table__cell {
          // color: $primary-alt;
          // background-color: transparent;
          height: 55px;
        }

        td {
          height: 60px;
          color: $white;
        }
      }

      .table_list_top {
        img {
          height: 27px;
          @include rtl-sass-prop(margin-right, margin-left, 10px);
        }

        span {
          color: $white;
        }
      }

      .table_list_content {
        height: 225px;
      }

      .contact ul li {
        &:not(:last-child) {
          margin-bottom: 25px;
        }

        img {
          width: 20px;
          vertical-align: middle;
          @include rtl-sass-prop(margin-right, margin-left, 15px);
        }

        span,
        a {
          color: $white;
          font-size: 15px;
        }

        &:nth-child(1) {
          span:nth-child(4) {
            @include rtl-sass-prop(padding-left, padding-right, 40px);
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .wrapper {
    .query {
      margin-bottom: 20px;

      .calendar_content {
        grid-template-columns: 100%;
        grid-template-areas:
          'left_box'
          'calendar'
          'right_box';

        .left_box {
          margin-bottom: 20px;
          @include rtl-sass-prop(margin-right, margin-left, 0);
        }

        .calendar {
          margin-bottom: 20px;
        }

        .right_box {
          justify-self: center;
        }
      }
    }
  }
}