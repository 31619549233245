/deep/ .el-dialog {
  box-shadow: unset;
  background-color: unset;
  .el-dialog__body {
    padding: 0;
  }

  .el-dialog__header {
    display: none;
  }
}
.brazil_banner {
  position: relative;
  .closeImg {
    position: absolute;
    top: 3%;
    right: 3%;
    cursor: pointer;
  }
  .brazil_content {
    position: absolute;
    top: 60%;
    color: $white;
    width: 100%;
    text-align: center;
    line-height: 50px;
    .title3 {
      font-weight: bold;
      color: $red-orange;
      font-size: 24px;
    }

    .title4 {
      color: $white;
      font-size: 20px;
      padding: 0.3rem 0.5rem;
    }
    .brazil_btn {
      min-width: 60%;
      height: 35px;
      background-color: $red-orange;
      border: 1px;
      border-radius: 50px;
      font-size: 16px;
      .btn_text {
        color: $white;
      }
    }
  }
}

.banner {
  position: relative;
  font-weight: bold;

  .closeImg {
    position: absolute;
    top: 3%;
    right: 3%;
    cursor: pointer;
  }

  .btn {
    position: relative;
    top: -60px;
    @include rtl-sass-value(left, 10px, -280px);
    .el-button {
      padding: 0.3rem 0.5rem;
      min-width: 25%;
      em {
        transform: rotate(0deg);
        float: right;
      }
    }
  }
}

@media (max-width: 768px) {
  .banner {
    .btn.btn_ar {
      left: -200px;
    }
  }
}

@media (max-width: 500px) {
  .banner {
    .btn {
      top: -50px;
      .el-button {
        font-size: 10px;
      }
      &.btn_ar {
        left: -160px;
      }
    }
  }
}

@media (max-width: 400px) {
  .banner {
    .btn {
      top: -40px;
      &.btn_ar {
        left: -130px;
      }
    }
  }
}

@media (max-width: 300px) {
  .banner {
    .btn {
      top: -30px;
      &.btn_ar {
        left: -100px;
      }
    }
  }
}
