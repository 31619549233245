.echarts {
  /deep/ .el-col {
    // border-radius: 10px;
    // overflow: hidden;
  }

  /deep/ .col_t {
    // margin-left: 35px;
  }

  .item_header {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  .chart {
    // height: 450px;
    // box-shadow: inset 0px 0px 5px 2px $tropical-green;
    border: none;
    background: $theme-hue;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;

    .top {
      font-weight: bold;

      img {
        @include rtl-sass-prop(margin-right, margin-left, 10px);
      }
    }

    .content {
      height: 400px;
      padding: 20px 0;
      line-height: 400px;
      text-align: center;
      color: $white;
    }
  }
}